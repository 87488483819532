@if (content(); as c) {
    @let subheadline = locationHeadline();
    @let url = dealerHcmUrl();
    <section class="bg-grey-100 px-24 pb-12 pt-16 md:hidden">
        <h1 class="text-31 leading-36">{{ c.heroHeadline }}</h1>
        @if (subheadline) {
            <h2 class="text-16 leading-27">{{ subheadline }}</h2>
        }
    </section>
    <section>
        <ui-m030-c020-teaser-allrounder
            [strongText]="c.heroBulletListHeadline ?? undefined"
            [list]="heroBullets() ?? undefined"
            [image]="c.heroImage?.url ?? ''"
            [isFirstView]="true"
            [imageAlt]="c.heroImage?.alt || (c.heroHeadline ?? '')"
            [greyBackground]="firstElGreyBackground()"
        >
            <h1 class="hidden md:block" headline>{{ c.heroHeadline }}</h1>
            @if (subheadline) {
                <h2 class="mb-24 hidden md:block" subheadline>{{ subheadline }}</h2>
            }

            @if (!isOverlay()) {
                <ng-container cta>
                    @if (dealer() && dealer().hasMobileSellerId && c.heroCtaText; as cta) {
                        @if (url) {
                            <a
                                class="button-primary w-full lg:w-max"
                                [hyundaiRouterLink]="url"
                                [trackingElTarget]="url"
                                trackingElType="button"
                                tracking
                                trackingElLabel="Vehicle Market"
                            >
                                {{ cta }}
                            </a>
                        } @else {
                            <a class="button-primary w-full lg:w-max" [hyundaiRouterLink]="'/fahrzeugbestand'">
                                {{ cta }}
                            </a>
                        }
                    }
                </ng-container>
            }
        </ui-m030-c020-teaser-allrounder>
    </section>

    <section class="bg-white">
        <div class="inner-page-wrapper pb-40 pt-24 lg:pt-64">
            <h2 class="mb-24 text-24 leading-28 lg:mb-64 lg:text-center lg:text-34 lg:leading-41">
                {{ c.contentHeadline }}
            </h2>
            <div class="contents flex-row lg:flex lg:gap-56">
                <div class="lg:w-1/2 lg:flex-1">
                    @if (c.contentImage; as image) {
                        <ui-m000-c010-image
                            class="bg-grey-100"
                            [alt]="image.alt || (c.contentHeadline ?? '')"
                            [image]="{
                                image: image.url,
                                desktop: { width: 800, height: 600 },
                                tablet: { width: 800, height: 500 },
                                mobile: { width: 700, height: 280 },
                            }"
                            priority
                            cover
                        />
                    }
                    @let contentCopy = c.contentCopy || '' | footnotes;
                    <p class="py-24 text-16 leading-27" [innerHTML]="contentCopy()"></p>
                </div>

                <div class="flex flex-col gap-12 pt-12 lg:w-1/2 lg:flex-1 lg:pt-0">
                    <h3 class="text-16 leading-27 lg:text-22 lg:leading-28" id="vorteile">
                        {{ c.contentBulletListHeadline }}
                    </h3>
                    <ul class="flex flex-col gap-12">
                        @for (item of c.contentBulletList; track $index) {
                            <li class="flex items-center gap-8 break-words">
                                @if (item.icon; as icon) {
                                    <ui-m000-c010-image
                                        class="shrink-0 self-start"
                                        [image]="{
                                            image: icon.url,
                                            desktop: { width: 40, height: 40 },
                                            tablet: { width: 40, height: 40 },
                                            mobile: { width: 40, height: 40 },
                                        }"
                                        [alt]="icon.alt || item.text"
                                        classList="size-34  lg:size-40 aspect-square"
                                        priority="false"
                                        cover
                                    />
                                } @else {
                                    <i class="icon-placeholder text-34 lg:text-[40px]"></i>
                                }
                                @let itemText = item.text | footnotes;
                                <p class="break-words" [innerHTML]="itemText()"></p>
                            </li>
                        }
                    </ul>
                    @if (!isOverlay() && dealer() && dealer().hasMobileSellerId) {
                        @if (c.heroBulletListLinkText; as cta) {
                            @if (url) {
                                <a
                                    class="button-primary mt-8 lg:w-max"
                                    [hyundaiRouterLink]="url"
                                    [trackingElTarget]="url"
                                    trackingElType="button"
                                    tracking
                                    trackingElLabel="Vehicle Market"
                                >
                                    {{ cta }}
                                </a>
                            } @else {
                                <a class="button-primary mt-8 lg:w-max" [hyundaiRouterLink]="'/fahrzeugbestand'">
                                    {{ cta }}
                                </a>
                            }
                        }
                    }
                </div>
            </div>
        </div>
    </section>

    @defer {
        <ng-content />

        @if (c.legalLines; as legalLines) {
            @if (legalLines.html.length > 7) {
                <section class="inner-page-wrapper py-24 text-14 leading-20 text-grey-400">
                    <p class="empty:*:h-24" [innerHTML]="legalLines.html"></p>
                </section>
            }
        }
    }
}
